<template>
  <div class="container">
    <div class="is-size-4 center">Zdarzenia zarejestrowane w serwisie</div>
  </div>
</template>

<script>
export default {
  name: "AdminEvents",
};
</script>

<style lang="scss" scoped>
</style>